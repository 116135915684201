<template>
    <TabView class="custom-tabview"> 
        <TabPanel header="Tour Guide List">
            <div class="guide-list">
                <div class="search-bar">
                    <div class="p-input-icon-left search-field">
                        <i class="pi pi-search" />
                        <InputText type="text" v-model="filters['global'].value" placeholder="Search by name" />
                    </div>
                    <router-link to="/tourguide/add-tour-guide" target="_blank"> 
                        <Button v-if="can('create', 'tour guide')" label="Add" class="p-button-sm" icon="pi pi-plus" />
                    </router-link>
                </div>

                <div class="filter-bar">
                    <div class="p-fluid p-grid p-field">
                        <div class="p-col p-mb-2">
                            <div style="display: flex;">
                                <label class="p-mr-2" for="filter_area">Area:</label>
                                <Dropdown v-model="selectedAreaFilter" style="width: 100%" :options="filterAreaDropdown" optionLabel="area">
                                </Dropdown> 
                            </div>
                        </div>

                        <div class="p-col">
                            <div style="display: flex;">
                                <label class="p-mr-2" for="filter_language">Language:</label>
                                <Dropdown v-model="selectedLanguageFilter" style="width: 100%" :options="filterLanguageDropdown" optionLabel="language">
                                </Dropdown>
                            </div>
                        </div>

                        <div class="p-col-fixed filter-buttons">
                            <Button label="Filter" @click="filterTourGuide()" class="p-mr-1 p-button-sm p-button-warning filter-button" icon="pi pi-filter" />
                            <Button label="Clear" @click="clearFilterTourGuide()" class="p-button-sm p-button-secondary filter-button" icon="pi pi-filter-slash" />
                        </div>
                    </div>
                </div>
                
                <div class="content-section implementation">
                    <div class="card">
                        <DataTable
                            :value="tourguides"
                            responsiveLayout="scroll"
                            scrollHeight="600px"
                            v-model:selection="selectedTourGuide" 
                            selectionMode="single"
                            v-model:filters="filters"
                            :globalFilterFields="['first_name', 'last_name']"
                            dataKey="id"
                        >
                            <!-- <Column field="index" header="No." :headerStyle="{'max-width':'90px','min-width':'50px'}" :bodyStyle="{'max-width':'90px','min-width':'50px'}"> -->
                            <Column field="id" header="ID." > </Column>
                            <Column field="first_name" header="First Name" :style="{'min-width':'130px'}"></Column>
                            <Column field="last_name" header="Last Name" :style="{'min-width':'130px'}"></Column>
                            <Column field="sex" header="Gender" :style="{'min-width':'50px'}"></Column>
                            <Column field="guidingarea" header="Area" :style="{'min-width':'120px'}"></Column>
                            <Column field="phone1" header="Phone 1" :style="{'min-width':'120px'}"></Column>
                            <Column field="phone2" header="Phone 2" :style="{'min-width':'120px'}"></Column>
                            <Column field="email" header="Email" ></Column>
                            
                            <Column v-if="can('update', 'tour guide') || can('delete', 'tour guide')" :exportable="false" :style="{'min-width':'90px', 'padding':'0.4rem 0.7rem', 'text-align':'right'}">
                                <template #body="slotProps">
                                    <router-link :to="'/tourguide/update-tour-guide/'+slotProps.data.id" target="_blank"> 
                                        <Button v-if="can('update', 'tour guide')" icon="pi pi-pencil" class="p-button-rounded p-button-outlined p-button-success small-datatable-button p-mr-2" />
                                    </router-link>
                                    <Button v-if="can('delete', 'tour guide')" icon="pi pi-trash" class="p-button-rounded p-button-outlined p-button-danger small-datatable-button p-mr-2" @click="confirmDeleteTourGuide(slotProps.data)" />
                                </template>
                            </Column>

                        </DataTable>
                        <ConfirmDialog></ConfirmDialog>
                    </div>
                </div>
            </div>
        </TabPanel>

        <TabPanel header="Tour Guide Fee">
            <TourGuideFee />
        </TabPanel>
    </TabView>
</template>
<script>
import { ref, onMounted } from 'vue';
import { useConfirm } from "primevue/useconfirm";
import { useToast } from "primevue/usetoast";
import { useAbility } from '@casl/vue';
import {FilterMatchMode} from 'primevue/api';

import TourGuideService from '../service/TourGuideService';
import GuidingAreaService from '../service/GuidingAreaService';
import TourGuideFee from './TourGuideFee.vue'

export default {
    setup(){
        const { can } = useAbility()
        const confirm = useConfirm();
        const toast = useToast()
        let selectedTourGuide = ref()
        let tourguides = ref([])
        const filters = ref({
            'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
        });

        let filterAreaDropdown = ref([])
        let filterLanguageDropdown = ref([])
        const selectedAreaFilter = ref()
        const selectedLanguageFilter = ref()

        //On Created
        const tourguideService = ref(new TourGuideService());
        const guidingAreaService = ref(new GuidingAreaService())

        //On Mounted
        onMounted(() => {
            getTourGuidesData();

            setAreaFilterDropdown()
            setLanguageFilterDropdown()
        })

        //Methods
        const getTourGuidesData = () => {
            tourguideService.value.getTourGuides().then((data) => {
                if(!data.errorResponse) {
                    tourguides.value = data;
                } else {
                    toast.add({severity:'warn', summary: 'Error ' + data.status, detail: data.errorResponse, life: 5000});
                }
            });
        }

        const confirmDeleteTourGuide = (data) => {
            confirm.require({
                message: 'Are you sure you want to delete '+ data.first_name + ' ' + data.last_name +'?',
                header: 'Delete Confirmation',
                icon: 'pi pi-info-circle',
                acceptClass: 'p-button-danger',
                accept: () => {        
                    const tourguide_id = data.id      
                    const photo = data.photo
                    tourguideService.value.deleteTourGuide(tourguide_id, photo).then((data) => {
                        if(!data.errorResponse){
                            // getTourGuidesData();
                            tourguides.value = tourguides.value.filter(val => val.id !== tourguide_id);
                        } else {
                            // alert('Something went wrong while deleting tour guide.');
                            toast.add({severity:'error', summary: 'Delete Error ' + data.status, detail: data.errorResponse, life: 5000});        
                        }
                    })
                }
            });
        }

        //==================== Filter Tour Guide ====================
        const setAreaFilterDropdown = () => {
            guidingAreaService.value.getGuidingArea().then(data => {
                filterAreaDropdown.value = [{id: 0, area: 'Any'}]
                for(const item of data) {
                    filterAreaDropdown.value = [...filterAreaDropdown.value, {id: item.id, area: item.area}]
                }
                selectedAreaFilter.value = filterAreaDropdown.value[0]
            });
        }
        
        const setLanguageFilterDropdown = () => {
            tourguideService.value.getLanguages().then(data => {
                filterLanguageDropdown.value = [{id: 0, language: 'Any'}]
                for(const item of data) {
                    filterLanguageDropdown.value = [...filterLanguageDropdown.value, {id: item.id, language: item.language}]
                }
                selectedLanguageFilter.value = filterLanguageDropdown.value[0]
            });
        }

        const filterTourGuide = () => {
            const areaFilter = selectedAreaFilter.value
            const languageFilter = selectedLanguageFilter.value

            if(areaFilter.id == 0 && languageFilter.id == 0){
                getTourGuidesData()
            } else {
                tourguideService.value.filterTourGuide(areaFilter, languageFilter).then(data => {
                    tourguides.value = data
                })
            }
        }

        const clearFilterTourGuide = () => {
            getTourGuidesData()
            selectedAreaFilter.value = filterAreaDropdown.value[0]
            selectedLanguageFilter.value = filterLanguageDropdown.value[0]
        }

        return {
            can,
            filters,
            selectedTourGuide,
            tourguides,

            filterAreaDropdown,
            filterLanguageDropdown,
            selectedAreaFilter,
            selectedLanguageFilter,
            filterTourGuide,
            clearFilterTourGuide,
            
            confirmDeleteTourGuide,
        }
    },
    // data() {
    //     return {
    //         loading: false,
    //         filters: {},
    //         search_value: '',
    //         tourguide_note: 'Note: ',
    //         // tourguides: [
    //         //     { id: '1', first_name: 'ABC', last_name: 'ABC', sex: 'M' },
    //         //     { id: '2', first_name: 'ABC', last_name: 'ABC', sex: 'M' },
    //         //     { id: '3', first_name: 'ABC', last_name: 'ABC', sex: 'M' },
    //         //     { id: '4', first_name: 'ABC', last_name: 'ABC', sex: 'M' },
    //         //     { id: '5', first_name: 'ABC', last_name: 'ABC', sex: 'M' },
    //         //     { id: '6', first_name: 'ABC', last_name: 'ABC', sex: 'M' },
    //         //     { id: '7', first_name: 'ABC', last_name: 'ABC', sex: 'M' },
    //         //     { id: '8', first_name: 'ABC', last_name: 'ABC', sex: 'M' },
    //         //     { id: '9', first_name: 'ABC', last_name: 'ABC', sex: 'M' },
    //         //     { id: '10', first_name: 'ABC', last_name: 'ABC', sex: 'M' },
    //         //     { id: '11', first_name: 'ABC', last_name: 'ABC', sex: 'M' },
    //         //     { id: '12', first_name: 'ABC', last_name: 'ABC', sex: 'M' },
    //         // ],
    //         selectedTourGuide: null,
    //         tourguides: null,
    //     };
    // },
    // tourguideService: null,
    // created() {
    //     this.tourguideService = new TourGuideService();
    // },
    // mounted() {
    //       this.loading = true;
          
    //       this.getTourGuidesData();
    // },
    // methods: {
    //     getTourGuidesData(){
    //         this.tourguideService.getTourGuides().then((data) => { 
    //               this.tourguides = data;
    //               this.loading = false;
    //           });
    //     },
    //     onRowSelect(event){
    //         this.tourguide_note = 'Note: ' + event.data.note;
    //     },
    //     confirmDeleteTourGuide(data) {
    //         this.$confirm.require({
    //             message: 'Are you sure you want to delete '+ data.first_name + ' ' + data.last_name +'?',
    //             header: 'Delete Confirmation',
    //             icon: 'pi pi-info-circle',
    //             acceptClass: 'p-button-danger',
    //             accept: () => {
    //                 this.tourguideService.deleteTourGuide(data.id).then((data) => {
    //                     if(data != ''){
    //                         // this.getTourGuidesData();
    //                         this.tourguides = this.tourguides.filter(val => val.id !== data.id);
    //                     } else {
    //                         alert('Something went wrong while deleting tour guide.');
    //                     }
    //                 })
    //             }
    //         });
    //         // console.log(data)
    //     },
    // },
    components: {
        TourGuideFee,
    },
};
</script>

<style lang="scss" scoped>
    .guide-list .card {
        height: 620px;
    }
    .p-button-icon-only.p-button-rounded {
        height: 28px;
    }
    .small-button {
        width: 28px;
    }
</style>
