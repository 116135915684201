<template>
    <div class="guide-fee">
        <div class="filter-bar">
            <div class="p-fluid p-grid p-field">
                <div class="p-col p-mb-2">
                    <div style="display: flex;">
                        <label class="p-mr-2" for="area">Area:</label>
                        <Dropdown style="width: 100%" v-model="selectedArea" :options="areaDropdown" optionLabel="area" @change="getGuideFee()">
                        </Dropdown> 
                    </div>
                </div>
                <div class="p-col">
                    <div style="display: flex;">
                        <label class="p-mr-2" for="language">Language:</label>
                        <Dropdown style="width: 100%" v-model="selectedLanguage" :options="languageDropdown" optionLabel="language" @change="getGuideFee()">
                        </Dropdown>
                    </div>
                </div>
                
                <div class="p-col-fixed filter-buttons">
                    <Button label="Add Fee" style="width: 80px" @click="openGuideFeeDialog('add')" class="p-mr-1 p-button-sm filter-button" />
                </div>
            </div>
        </div>
        
        <div class="content-section implementation">
            <div class="card">
                <DataTable
                    :value="guidefee"
                    responsiveLayout="scroll"
                    scrollHeight="500px"
                    v-model:selection="selectedGuideFee" 
                    selectionMode="single"
                    dataKey="id"
                    rowGroupMode="subheader"
                    groupRowsBy="service_fee_type"
                    class="custom-rowgroup-table"
                    :loading="loading"
                >
                    <!-- <Column field="id" header="No." > </Column> -->
                    <Column field="desc" header="Description" :style="{'min-width':'180px'}"></Column>
                    <Column field="service_fee_type" header="" :style="{'min-width':'180px'}"></Column>
                    <Column field="fit_fee" header="FIT Fee (USD)" :style="{'min-width':'120px'}"></Column>
                    <Column field="git_fee" header="GIT Fee (USD)" :style="{'min-width':'120px'}"></Column>
                    
                    <Column :exportable="false" :style="{'min-width':'90px', 'padding':'0.4rem 0.7rem', 'text-align':'right'}">
                        <template #body="slotProps">
                            <Button icon="pi pi-pencil" class="p-button-rounded p-button-outlined p-button-success small-datatable-button p-mr-2" @click="openGuideFeeDialog('update', slotProps.data)"/>
                            <Button icon="pi pi-trash" class="p-button-rounded p-button-outlined p-button-danger small-datatable-button p-mr-2" @click="confirmDeleteGuideFee(slotProps.data)" />
                        </template>
                    </Column>

                    <template #groupheader="slotProps">
                        <span style="font-weight: 500;">{{slotProps.data.service_fee_type}}</span>
                    </template>
                </DataTable>
                <ConfirmDialog></ConfirmDialog>
            </div>

            <Dialog v-model:visible="showAddGuideFeeDialog" :style="{width: '55rem'}" :modal="true">
                <template #header>
                    <h5 style="margin: 0px">{{dialogHeader}}</h5>
                </template>
                <div class="p-grid">
                    <div class="p-col-fixed">
                        <div class="guidefee-label">
                            <label>Area:</label>
                        </div>
                    </div>
                    <div class="p-col">
                        <span style="font-weight: 500;">{{selectedArea.area}}</span>
                    </div>
                </div>
                <div class="p-grid">
                    <div class="p-col-fixed">
                        <div class="guidefee-label">
                            <label>Language:</label>
                        </div>
                    </div>
                    <div class="p-col">
                        <span style="font-weight: 500;">{{selectedLanguage.language}}</span>
                    </div>
                </div>

                <div class="p-grid p-fluid">
                    <div class="p-col-6">
                        <label>Fee Type: </label>
                        <div style="display: flex; margin-top: .2rem">
                            <Dropdown style="width: 100%" v-model="selectedFeeType" :options="guidefee_type" optionLabel="type" />
                        </div>
                    </div>
                    <div class="p-col-6">
                        <label>Description: </label>
                        <div class="custom-row">
                            <InputText id="desc" v-model="guidefee_form.desc" type="text" :class="{'p-invalid':v$.desc.$error}"/>
                        </div>
                    </div>
                </div>
                <div class="p-grid p-fluid p-mb-3">
                    <div class="p-col-6">
                        <label>FIT Fee (USD): </label>
                        <div class="custom-row">
                            <InputNumber id="fit_fee" v-model="guidefee_form.fit_fee" mode="decimal" inputStyle="width: 8rem" :class="{'p-invalid':v$.fit_fee.$error}"/>
                        </div>
                        <small v-if="v$.fit_fee.$error" class="p-error">Fee must be > 0</small>
                    </div>
                    <div class="p-col-6">
                        <label>GIT Fee (USD): </label>
                        <div class="custom-row" >
                            <InputNumber id="git_fee" v-model="guidefee_form.git_fee" mode="decimal" inputStyle="width: 8rem" :class="{'p-invalid':v$.git_fee.$error}"/>
                        </div>
                        <small v-if="v$.git_fee.$error" class="p-error">Fee must be > 0</small>
                    </div>
                </div>
                <div class="p-grid">
                    <div class="p-col-fixed">
                        <div v-if="form_type == 'add'">
                            <Button label="Save" icon="pi pi-save" class="p-button-success" @click="saveGuideFee()" />
                        </div>
                        <div v-else>
                            <Button label="Update" icon="pi pi-save" class="p-button-warning" @click="updateGuideFee()" />
                        </div>
                    </div>
                    <div class="p-col-fixed">
                        <InlineMessage v-if="messages.isMsgShow" :severity="messages.severity" class="p-ml-4">{{ messages.content }}</InlineMessage>
                    </div>	
                </div>
            </Dialog>
        </div>
    </div>
</template>

<script>
import { ref, reactive, onMounted, computed } from 'vue'
import{ useStore } from 'vuex'
import { useToast } from "primevue/usetoast";
import { useConfirm } from "primevue/useconfirm";
import useVuelidate from '@vuelidate/core';
import { required, numeric, minValue } from '@vuelidate/validators';

import TourGuideService from '../service/TourGuideService';
import GuidingAreaService from '../service/GuidingAreaService';
import TourGuideFeeService from '../service/TourGuideFeeService'

export default {
    setup() {
        const store = useStore()
        const toast = useToast()
        const confirm = useConfirm()

        let loading = ref(false)
        let areaDropdown = ref([])
        let languageDropdown = ref([])
        const selectedArea = ref()
        const selectedLanguage = ref()

        let guidefee = ref([])
        let selectedGuideFee = ref()

        let dialogHeader = ref('')
        const showAddGuideFeeDialog = ref(false)

        //On Create
        const tourguideService = ref(new TourGuideService());
        const guidingAreaService = ref(new GuidingAreaService())
        const guideFeeService = ref(new TourGuideFeeService())

        onMounted(() => {
            setAreaDropdown()
            setLanguageDropdown()

            selectedFeeType.value = guidefee_type.value[0]
        })

        //====================Methods====================
        const setAreaDropdown = () => {
            guidingAreaService.value.getGuidingArea().then(data => {
                for(const item of data) {
                    areaDropdown.value = [...areaDropdown.value, {id: item.id, area: item.area}]
                }
                selectedArea.value = areaDropdown.value[0]
            });
        }
        
        const setLanguageDropdown = () => {
            tourguideService.value.getLanguages().then(data => {
                for(const item of data) {
                    languageDropdown.value = [...languageDropdown.value, {id: item.id, language: item.language}]
                }
                selectedLanguage.value = languageDropdown.value[0]
            });
        }
        
        const getGuideFee = () => {
            const waitAPI = setTimeout(() => {
                loading.value = true
            }, 800)
            const data = {
                language_id: selectedLanguage.value.id, 
                area_id: selectedArea.value.id
            }
            guideFeeService.value.getTourGuideFee(data).then(data => {
                if(!data.errorResponse) {
                    guidefee.value = data

                    clearInterval(waitAPI)
                    loading.value = false
                } else {
                    toast.add({severity:'error', summary: 'Getting Data Error ' + data.status, detail: data.errorResponse, life: 6000});        
                }
            })
        }

        const openGuideFeeDialog = (formtype, data) => {
            showAddGuideFeeDialog.value = true
            form_type.value = formtype
            
            initFormData()
            v$.value.$reset() //Reset validations

            if(formtype == 'add'){
                dialogHeader.value = 'Add Guide Fee'
            } else {
                dialogHeader.value = 'Update Guide Fee'

                selectedFeeType.value = {type: data.service_fee_type}
                guidefee_id.value = data.id
                const guidefee_data = {
                    desc: data.desc,
                    fit_fee: data.fit_fee,
                    git_fee: data.git_fee
                }
                Object.assign(guidefee_form, guidefee_data)
            }
        }

        //======================GUIDE FEE FORM====================
        let guidefee_type = ref([
            {type: 'Service Fee'},
            {type: 'Add-on Fee'}
        ])
        let selectedFeeType = ref()
        let guidefee_form = reactive({})
        let guidefee_id = ref(0)
        let form_type = ref('')
        
        let messages = computed(() => store.state.showMsg)

        const initFormData = () => {
            const initForm = {
                service_fee_type: '',
                desc: '',
                fit_fee: 0,
                git_fee: 0,
                language_id: '',
                guidingarea_id: '',
            }

            Object.assign(guidefee_form, initForm)

            guidefee_form.language_id = selectedLanguage.value.id
            guidefee_form.guidingarea_id = selectedArea.value.id
        }

        const saveGuideFee = () => {
            guidefee_form.service_fee_type = selectedFeeType.value.type            

            if(validateForm()) {
                guideFeeService.value.addGuideFee(guidefee_form).then(data => {
                    if(!data.errorResponse) {
                        showMessage('success', 'Guide fee was added successfully.', 4000)
                        getGuideFee()
                        initFormData()
                        v$.value.$reset() //Reset validations
                    } else {
                        showMessage('warn', data.errorResponse, 5000)
                    }
                })
            }
        }

        const updateGuideFee = () => {
            guidefee_form.service_fee_type = selectedFeeType.value.type

            if(validateForm()) {
                delete guidefee_form.language_id
                delete guidefee_form.guidingarea_id

                guideFeeService.value.updateGuideFee(guidefee_id.value, guidefee_form).then(data => {
                    if(!data.errorResponse) {
                        showMessage('success', 'Guide fee was updated successfully.', 4000)
                        getGuideFee()
                    } else {
                        showMessage('warn', data.errorResponse, 5000)
                    }
                })
            }
        }

        const confirmDeleteGuideFee = (data) => {
            confirm.require({
                message: 'Are you sure you want to delete this "' + data.desc +'" fee?',
                header: 'Delete Confirmation',
                icon: 'pi pi-info-circle',
                acceptClass: 'p-button-danger',
                accept: () => {
                    const guidefee_id = data.id
                    guideFeeService.value.deleteGuideFee(guidefee_id).then((data) => {
                        if(!data.errorResponse){
                            guidefee.value = guidefee.value.filter(val => val.id !== guidefee_id);
                        } else {
                            showMessage('warn', data.errorResponse, 5000)
                        }
                    })
                }
            });
        }

        const showMessage = (severity, content, life) => {
            let message = {
                isMsgShow: true, 
                severity: severity, 
                content: content
            }

            store.dispatch('showMsg', message)
            if(life) {
                setTimeout(() => {
                    store.dispatch('showMsg', { isMsgShow: false, severity: '', content: '' })
                }, life);
            }
        }

        //======================== Form Validation ========================
        const rules = computed(() =>  {
            return {
                desc: { required },
                fit_fee: { numeric, required, minValue: minValue(1) },
                git_fee: { numeric, required, minValue: minValue(1) },
            }            
        })
        const v$ = useVuelidate(rules, guidefee_form)
        const validateForm = () => {
            v$.value.$validate();
            if(!v$.value.$error){
                return true
            } else {
                return false
            }
        }

        return {
            areaDropdown,
            languageDropdown,
            selectedArea,
            selectedLanguage,

            loading,
            guidefee,
            selectedGuideFee,
            getGuideFee,

            guidefee_type,
            selectedFeeType,

            v$,
            dialogHeader,
            form_type,
            openGuideFeeDialog,
            showAddGuideFeeDialog,
            saveGuideFee,
            updateGuideFee,
            guidefee_form,
            confirmDeleteGuideFee,

            messages,
        }
    },
}
</script>

<style lang="scss" scoped>
.guide-fee .card {
    height: 530px;
}
.p-button-icon-only.p-button-rounded {
    height: 28px;
}
.small-button {
    width: 28px;
}
.filter-button {
    width: 5rem;
}
.custom-row {
    margin-top: 0.2rem;
}
.guidefee-label {
    margin-bottom: 10px;
    width: 80px;
}
</style>