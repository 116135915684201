import axios from '../axios.instance'

export default class GuidingAreaService {
    async getGuidingArea(){
        try {
            const res = await axios.get('/guidingarea');
            return res.data;
        } catch (err) {
            console.log(err);
        }
    }

    async addGuidingArea(data){
        try {
            if(data !== ''){
                const res = await axios.post('/guidingarea', {data});
                return res.data;
            }
        } catch (error) {
            return error.response.data
        }
    }

    async updateGuidingArea(area_id, data) {
        try {
            if(data != ''){
                const res = await axios.put('/guidingarea/'+area_id, {data})
                return res.data;
            }
        } catch (error) {
            return error.response.data
        }
    }
    
    async deleteGuidingArea(area_id){
        try {
            if(area_id != 0) {
                const res = await axios.delete('/guidingarea/' + area_id);
                return res.data;
            }
        } catch (error) {
            return error.response.data
        }
    }
}