import axios from '../axios.instance'

export default class TourGuideFeeService {

    async getTourGuideFee(data){
        try {
            if(data != ''){
                const res = await axios.post('/tourguidefee', {data})
                return res.data;
            }
        } catch (error) {
            return error.response.data
        }
    }

    async addGuideFee(data){
        try {
            if(data != ''){
                const res = await axios.post('/tourguidefee/add-guidefee', {data})
                return res.data;
            }
        } catch (error) {
            return error.response.data
        }
    }

    async updateGuideFee(guidefee_id, data){
        try {
            if(data != '') {
                const res = await axios.put('/tourguidefee/'+guidefee_id, {data})
                return res.data;
            }
        } catch (error) {
            return error.response.data
        }
    }

    async deleteGuideFee(guidefee_id){
        try {
            if(guidefee_id != 0) {
                const res = await axios.delete('/tourguidefee/'+guidefee_id)
                return res.data;
            }
        } catch (error) {
            return error.response.data
        }
    }
}